import linkImage from "../../../assets/images/homeA.png"

export const Contact = () => {

    return (
        <main className='Contact'>
            <section className='Contact_container'>
                <div className='Contact_image'>
                    <img src={linkImage} alt="decoration de la page contact"></img>
                </div>
                <div className='Contact_text'>
                    <h1>Contact us</h1>
                    <div className='Contact_text_liste'>
                        <p>Phone: +32 486 33 99 79  </p>
                        <p>Email: info@sustainity.co</p>
                        <p>Adresse: Rue des Anciens Etangs 55, B-1190 Brussels, Belgium</p>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Contact 