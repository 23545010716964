import { useState, useEffect } from 'react'
import Tag from "./tag/Tag"
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Link, NavLink } from "react-router-dom";


export const CarteProduits = ({ produit }) => {

  const [linkImage, setIinkImage] = useState("")
  const [isHover, setIsHover] = useState(false)
  const [linkImageSecondaire, setIinkImageSecondaire] = useState("")
  const [loading, setLoading] = useState("")
  const [classNameAttribut, setClassNameAttribut] = useState("CarteProduits")
  const generateTags = () => {
    const listeDeTag = []
    produit[1].lables.forEach(element => {
      listeDeTag.push(<Tag label={element.value} key={element.label} />)
    })
    return listeDeTag
  }

  const storage = getStorage();
  const storageRefImageMain = ref(storage, "products-images/" + produit[0] + "/mainImage");
  const storageRefImageSecondaire = ref(storage, "products-images/" + produit[0] + "/secondaryImage1");

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }



  useEffect(() => {
    const getImage = async () => {
      setIinkImage(await getDownloadURL(storageRefImageMain))
      setIinkImageSecondaire(await getDownloadURL(storageRefImageSecondaire))
    }
    getImage()
    setLoading(true)
  }, [loading])


  const handleMouseEnter = () => {
    setClassNameAttribut("CarteProduits CarteProduits_hover")
    setIsHover(true);

  }

  const handleMouseLeave = () => {
    setClassNameAttribut("CarteProduits")
    setIsHover(false);
  }

  const generateImage = () => {
    return isHover ? <img src={linkImageSecondaire}></img> : <img src={linkImage}></img>
  }

  if (loading) {
    return (
      <NavLink
        className={classNameAttribut}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={scrollToTop}
        to={"/shop/product/" + produit[0]}
      >
        {generateImage()}
        <div className="CarteProduits_text">
          <h2>{produit[1].nom}</h2>
          <p>From {produit[1].prixUniteMin}€</p>
        </div>
      </NavLink >
    )
  } else {
    return (
      <div className="CarteProduits">
        Chargement
      </div>
    )
  }
}
export default CarteProduits