export const AddPanier = ({setAddButtonValid, optionColor, optionDecoration, quantity, quantityMin ,prixUniteMax, prixUniteMin, onClickAddPanier}) => {


    const isValid = () => {
        if(optionColor != "" && optionDecoration != "" 
        && quantity >= quantityMin && optionColor != ""){
            setAddButtonValid(true)
            return true
        }
        return false
    }

    const AddPanierValid = isValid() ? "AddPanier AddPanier_valid" : "AddPanier"
    
    const euro = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      });

    const prixMinGenerate = () => {
        let number = quantity * prixUniteMin
        return euro.format(number)
    }

    return (
        <button onClick={onClickAddPanier} className={AddPanierValid}>
           <p>Add to Cart</p>
           <div className="AddPanier_text">
            <p>{prixMinGenerate()}</p>
            <p>+</p>
           </div>
        </button>
    )

}
export default AddPanier