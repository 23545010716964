import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { useEffect, useState } from "react"

export const CartePanierMinimaliste = ({ produitPanier}) => {
    const [imageSrc, setImageSrc] = useState(null)
    const [loading, setLoading] = useState(false)
    const storage = getStorage();

    
    useEffect(() => {
        const fetchData = async () => {
            const fetchData = async () => {
                if (produitPanier.optionColor === "Black" || produitPanier.optionColor === "Silver") {
                    setImageSrc(await getDownloadURL(ref(storage, "products-images/" + produitPanier.idProduct + "/mainImage")))

                } else {
                    setImageSrc(await getDownloadURL(ref(storage, "products-images/" + produitPanier.idProduct + "/" + produitPanier.optionColor)))
                }
                setLoading(true)
            }
            fetchData()
            setLoading(true) 
        }
        fetchData()
    }, [loading]
    )

    if (loading) {
        return (
            <div className="CartePanierMinimaliste">
                <img src={imageSrc} className="CartePanierMinimaliste_image"></img>
                <div className="CartePanierMinimaliste_header">
                    <p>{produitPanier.product.nom}</p>
                    <p>X{produitPanier.quantity}</p>
                </div>
            </div>
        )
    } else {
        return (
            <div className="CartePanier">
                Chargement...
            </div>
        )
    }

}
export default CartePanierMinimaliste