import { useNavigate } from "react-router-dom"

export const ProductAriane = ({categorie, sousCategorie, nomProduit}) => {

    let navigate = useNavigate()
    const onClick = (e) => {
        navigate('/shop/' + e.target.value)
    }

    return (
        <div className="Ariane">
            <button className="Ariane_button" onClick={onClick} value={"All"}>/All</button>
            <button className="Ariane_button" onClick={onClick} value={categorie}>/{categorie}</button>
            <button className="Ariane_button" onClick={onClick} value={sousCategorie}>/{sousCategorie   }</button>
            <h1 className="Ariane_titre">/{nomProduit}</h1>
        </div>
    )
}
export default ProductAriane