export const OptionDecoration = ({ typeDecoration, optionDecoration, setOptionDecoration }) => {

    const onClick = (e) => {
        e.preventDefault()
        setOptionDecoration(e.target.value)
    }

    const generateButtonOprion = () => {
        let listeOption = []
        let index = 0
        typeDecoration.forEach(option => {
            index++
            if(option.value === optionDecoration){
                listeOption.push(<button key={index} className="OptionDecoration_choice_button OptionDecoration_choice_button_select" onClick={onClick} value={option.value}>{option.value}</button>)
            } else {
                listeOption.push(<button key={index} className="OptionDecoration_choice_button" onClick={onClick} value={option.value}>{option.value}</button>)
            }
        });
        return listeOption
    }
    return (
        <div className="OptionDecoration">
            <div className="OptionDecoration_text">
                <h3 >Decoration option: </h3>
                <p>{optionDecoration}</p>
            </div>
            <div className="OptionDecoration_choice">
                {generateButtonOprion()}
            </div>
        </div>
    )
}
export default OptionDecoration