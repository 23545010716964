import { Link } from "react-router-dom"

export const ContainerHome = ({ titre, text, text2, text3, text4, image, imgIsLeft, buttonLink, buttonTitle, buttonIsVisible }) => {

  const isLeft = imgIsLeft ? "ContainerHome imgArondi" : "ContainerHome"
  const isVisibleButton = buttonIsVisible ? "ContainerHome_button" : "ContainerHome_button_invisible"
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }



  return (
    <section className={isLeft}>
      <div className="ContainerHome_texts">
        <h2>{titre}</h2>
        <div className="ContainerHome_texts_text">
          <p>{text}</p>
          <p>{text2}</p>
          <p>{text3}</p>
          <p>{text4}</p>
        </div>
        <Link onClick={scrollToTop} to={buttonLink} className={isVisibleButton}>{buttonTitle}</Link>
      </div>
      <img src={image} alt={"Illustration de section: " + { titre }}></img>
    </section>
  )
}
export default ContainerHome