import { configureStore, combineReducers } from "@reduxjs/toolkit"
import panierReducer from "../feature/panier.slice"
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

/**
 * Configuration of Redux Persist
 */
const persistConfig = {
    key: "root",
    version: 1,
    storage
}

/**
 * Creation of reducers
 */
const reducer = combineReducers({
    panier: panierReducer,
})

const persistedReducer = persistReducer(persistConfig, reducer)

/**
 * Store configuration
 */
export default configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})