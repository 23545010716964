import { useNavigate } from "react-router-dom"
import close from "../../../assets/utils/close.svg"
import CartePanier from "./cartePanier/CartePanier"
import CompteurPanier from "./compteurPanier/CompteurPanier"
import PanierAriane from "../ariane/panierAriane/PanierAriane"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import panierLink from "../../../assets/utils/panier.svg"
import imagePanierLink from "../../../assets/utils/panier.svg"

export const Panier = ({ panierIsOpen, setPanierIsOpen }) => {

    let navigate = useNavigate()
    const listeItem = useSelector((state) => state.panier.panier)

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const OnClick = () => {
        setPanierIsOpen(!panierIsOpen)
    }

    const onClickReturnShop = () => {
        scrollToTop()
        navigate('/shop')
        setPanierIsOpen(!panierIsOpen)
    }

    const onClickFinalizeQuote = () => {
        scrollToTop()
        navigate("/order")
        setPanierIsOpen(!panierIsOpen)
    }

    const isOpen = panierIsOpen ? "Panier_container Panier_container_open" : "Panier_container"

    if (listeItem.length != 0) {
        return (
            <div className="Panier">
                <div className="Panier_buttons">
                    <button onClick={OnClick} className="Panier_buttons_button Panier_buttons_button_mobile"><img src={imagePanierLink}></img></button>
                    <button onClick={OnClick} className="Panier_buttons_button Panier_buttons_button_desk">Cart</button>
                    <CompteurPanier />
                </div>
                <div className={isOpen}>
                    <h1>Sustainity</h1>
                    <PanierAriane />
                    <h2>Your quote</h2>
                    <button onClick={OnClick} className="Menu_container_hero_close"><img src={close} alt="Close menu"></img></button>
                    <div className="Panier_container_listeProduit">
                        {listeItem.map((produitPanier) => (
                            <CartePanier key={produitPanier.id} id={produitPanier.id} produitPanier={produitPanier} />
                        ))}
                    </div>
                    <div className="Panier_container_buttons">
                        <button className="Panier_container_buttons_button" onClick={onClickReturnShop}>Continue shopping</button>
                        <button className="Panier_container_buttons_button" id="Button_Commande" onClick={onClickFinalizeQuote}>Finalize my quote</button>
                    </div>

                </div>
            </div>
        )
    } else {
        return (
            <div className="Panier">
                <div className="Panier_buttons">
                    <button onClick={OnClick} className="Panier_buttons_button Panier_buttons_button_mobile"><img src={panierLink} alt="Panier"></img></button>
                    <button onClick={OnClick} className="Panier_buttons_button Panier_buttons_button_desk">Cart</button>
                    <CompteurPanier />
                </div>
                <div className={isOpen}>
                    <h1>Sustainity</h1>
                    <PanierAriane />
                    <h2>Your quote</h2>
                    <button onClick={OnClick} className="Menu_container_hero_close"><img src={close} alt="Close menu"></img></button>
                    <div className="Panier_container_listeProduit">
                        <p>Your quote is empty</p>
                    </div>
                    <div className="Panier_container_buttons">
                        <button className="Panier_container_buttons_button" onClick={onClickReturnShop}>Continue shopping</button>
                        <button className="Panier_container_buttons_button" id="Button_Commande" onClick={onClickFinalizeQuote}>Finalize my quote</button>
                    </div>

                </div>
            </div>
        )
    }

}
export default Panier