import { collection, getDocs, getDoc, doc, addDoc, updateDoc } from "firebase/firestore";
import { db, fireStore } from "../config/Config";


export const getCollection = async (categorie) => {
    const querySnapshot = await getDocs(collection(fireStore, categorie));
    const data = []
    querySnapshot.forEach((doc) => {
        data.push([doc.id, doc.data()])
    })
    return data
}

export const getProduct = async (productId) => {
    const docRef = doc(db, "Products", productId);
    const docSnap = await getDoc(docRef);
    return docSnap.data()
}

export const addCommande = async (commande) => {
    const collectionRef= collection(db, "Commandes")
    const payload = commande
    const docRef = await addDoc(collectionRef, payload)
    return docRef.id
}   

export const setImageLink = async (productId, images) => {
    const imageLink = doc(db, "commandes", productId);
    await updateDoc(imageLink, images);
}