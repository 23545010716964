import { Link } from "react-router-dom"

export const MenuItem = ({text, link, menuIsOpen, setMenuIsOpen}) => {
  const OnClick = () => {
    setMenuIsOpen(!menuIsOpen)
  }

    return (
      <li className='MenuItem' onClick={OnClick}>
        <Link to={"/" + link}>{text}</Link>
        <span className="MenuItem_ligne"></span>
      </li>
    )
  }
  export default MenuItem