import { useState } from "react"

export const DropDown = ({ text, titre }) => {
    const [isOpen, setIsOpen] = useState(false)
    const open = isOpen ? "DropDown DropDown_open" : "DropDown"
    const onClick = () => {
        setIsOpen(!isOpen)
    }
    const textDecoration = isOpen ? "-" : "+"

    return (
        <div className={open} onClick={onClick}>
            <div className="DropDown_titre">
                <h3>{titre}</h3>
                <p>{textDecoration}</p>
            </div>
            <div className="DropDown_text">
                <p>{text}</p>
            </div>
        </div>
    )
}
export default DropDown