import { Route, Routes } from "react-router-dom";
import Layout from './layout/Layout';
import Home from "./home/Home";
import Shop from "./shop/Shop"

import { getCollection } from '../../services/caller.service'
import { useState, useEffect } from 'react'
import Product from "./product/Product";
import Login from "./login/Login";
import Commande from "./commande/Commande";
import Shipping from "./shipping/Shipping";
import Summary from "./summary/Summary";
import About from "./about/About";
import CommandeValidate from "./commandeValidate/CommandeValidate";
import Contact from "./contact/Contact";
import { Therms } from "./therms/Therms";

function PublicRoute() {
  const [data, setData] = useState(null)
  const [dataBestSeller, setDataBestSeller] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setData(await getCollection("Products"))
      setDataBestSeller(await getCollection("Bestsellers"))
      setLoading(true)
    }
    fetchData()
  }, [loading])

  const [socityInformation, setSocityInformation] = useState({
    email: "",
    company: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    vatNumber: "",

  })

  const [adresse, setAdresse] = useState({
    country: "",
    streetAdress: "",
    streetNumber: "",
    zipCode: "",
    city: ""
  })

  const [adresseFacturation, setAdresseFacturation] = useState({
    country: "",
    streetAdress: "",
    streetNumber: "",
    zipCode: "",
    city: ""
  })

  const [logoClient, setLogoClient] = useState(null)



  if (loading) {
    return (
      <Routes>
        <Route element={<Layout />}>
          <Route path='/' element={<Home dataBestSeller={dataBestSeller} />} />
          <Route path='/about' element={<About />} />
          <Route path='/shop/' element={<Shop data={data} />} />
          <Route path='/shop/:id' element={<Shop data={data} />} />
          <Route path='/shop/product/:id' element={<Product />} />
          <Route path='/login' element={<Login />} />
          <Route path='/order' element={<Commande
            socityInformation={socityInformation}
            setSocityInformation={setSocityInformation}
            adresse={adresse}
            setAdresse={setAdresse}
            setAdresseFacturation={setAdresseFacturation}
            setLogoClient={setLogoClient}
          />} />
          <Route path="/shipping" element={<Shipping
            adresseFacturation={adresseFacturation}
            setAdresseFacturation={setAdresseFacturation}
          />} />
          <Route path="/summary" element={<Summary
            socityInformation={socityInformation}
            adresse={adresse}
            adresseFacturation={adresseFacturation}
            setSocityInformation={setSocityInformation}
            setAdresse={setAdresse}
            setAdresseFacturation={setAdresseFacturation}
            logoClient={logoClient}
          />} />
          <Route path='/orderValidate' element={<CommandeValidate />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/therms' element={<Therms />} />
        </Route>
      </Routes>
    )
  } else {
    return loading
  }

}

export default PublicRoute