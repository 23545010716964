import { useNavigate } from "react-router"
import Validate from "../../../assets/utils/valide.svg"

export const CommandeValidate = () => {

    let navigate = useNavigate()

    const onClick = () => {
        navigate('/')
    }

    return (
        <main className='CommandeValidate'>
            <img className='CommandeValidate_img' src={Validate} alt="Icone de validation"></img>
            <div className='CommandeValidate_text'>
                <h1>Your order has been sent successfully.</h1>
                <p>Thank you for your order. We will now prepare a perfect offer for you.
                    Once your checkout is complete, we will come back to you within a half day!
                    In the meantime, contact us anytime for questions!
                </p>
            </div>
            <button onClick={onClick} className='CommandeValidate_button'>Back to home</button>
        </main>
    )
}
export default CommandeValidate