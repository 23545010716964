export const Therms = () => {

    return (
        <main className='Therms'>
            <section className='Therms_section'>
                <h1>General Conditions of Use (CGU)</h1>
                <div className='Therms_section_text'>
                    <h2>Introduction</h2>
                    <p>Welcome to Sustainity ("https://www.sustainity.co/"). These Terms and Conditions govern your use of the Site and its related services. By using the Site, you agree to be bound by these Terms of Use. If you do not agree to these Terms and Conditions, please do not use the Site.</p>
                </div>
                <div className='Therms_section_text'>
                    <h2>Use of the Site</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>You agree not to use the Site for any purpose that is illegal or prohibited by law.</p>
                        <p>You are responsible for maintaining the confidentiality of your account information and password. You agree to immediately notify Deep Core Expertise (Decorex srl) of any unauthorized use of your account.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Intellectual property</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>The content of the Site, including but not limited to texts, images, logos, videos and graphic elements, is protected by Belgian intellectual property laws. Deep Core Expertise (Decorex srl) owns all rights to the content of the Site.</p>
                        <p>You agree not to copy, reproduce, distribute, publish, display, modify or transmit any content of the Site without the prior written permission of Deep Core Expertise (Decorex srl).</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Protection of personal data</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>Deep Core Expertise (Decorex srl) collects, processes and stores your personal data in accordance with Belgian data protection laws. See our Privacy Policy for more information on how we process your data.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Disclaimer of Liability</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>Deep Core Expertise (Decorex srl) does not guarantee the accuracy, completeness or reliability of the content of the Site. You use the Site at your own risk.</p>
                        <p>Deep Core Expertise (Decorex srl) shall not be liable for any direct, indirect, special, consequential or punitive loss or damage arising from your use of the Site.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Modification of the CGU</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>Deep Core Expertise (Decorex srl) reserves the right to modify these T&Cs at any time. The modifications will take effect as soon as they are published on the Site. It is your responsibility to regularly check the T&Cs to be informed of any changes.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Governing Law and Jurisdiction</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>These T&Cs are governed by Belgian law. Any dispute arising from these Terms will be subject to the exclusive jurisdiction of the Belgian courts.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Contact information</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>If you have any questions or concerns regarding these T&Cs, please contact us at info@sustainity.co</p>
                    </div>
                </div>
            </section>
            <section className='Therms_section'>
                <h1>Privacy Policy</h1>
                <div className='Therms_section_text'>
                    <h2>Introduction</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>Deep Core Expertise (Decorex srl) ("we", "us", "our") is committed to protecting the privacy of its users ("you", "your"). This Privacy Policy describes how we collect, use, disclose and protect your personal data when you use our Sustainity website ("https://www.sustainity.co/").</p>
                        <p></p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Collection of Personal Data</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>We collect personal data that you voluntarily provide to us when you interact with the Site, including when you submit a quote request or contact us through forms or emails.</p>
                        <p>The personal data we collect may include, but is not limited to, your name, email address, phone number, and other information necessary to respond to your requests.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Use of Personal Data</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>We use the personal data you provide to us to respond to your quote requests and to communicate with you regarding our products or services.</p>
                        <p>We may also use your personal data to notify you of updates, promotions or special offers related to our products or services, unless you choose not to receive such communications.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Sharing of Personal Data</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>We do not sell, rent or disclose your personal data to third parties, except as necessary to respond to your requests or to comply with applicable laws.</p>
                        <p></p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Protection of personal data</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>We take steps to protect your personal data from unauthorized access, disclosure, alteration or destruction. However, no method of transmission over the Internet, or method of electronic storage is completely secure, and we cannot guarantee the absolute security of your data.</p>
                        <p></p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>User Rights</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>You have the right to request access, rectification, deletion or limitation of the use of your personal data. To exercise these rights, please contact us at info@sustainity.co.</p>
                        <p></p>
                    </div>
                </div>
            </section>
            <section className='Therms_section'>
                <h1>Cookies</h1>
                <div className='Therms_section_text'>
                    <h2>Introduction</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>Deep Core Expertise (Decorex srl) ("we", "us", "our") uses cookies on its Sustainity website ("https://www.sustainity.co/"). By using the Site, you consent to the use of cookies in accordance with this Cookies Policy.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>What is a cookie ?</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>A cookie is a small text file placed on your computer or device when you visit a website. Cookies allow the website to recognize your device on subsequent visits.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Cookie management</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>You have the ability to manage cookies by changing your browser settings. You can generally configure your browser to accept, refuse or delete cookies. However, please note that disabling cookies may affect your experience on the Site.</p>
                        <p>To find out more about managing cookies, see your browser instructions.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Consent</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>By using our Site, you consent to the use of cookies in accordance with this Cookies Policy.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Modification of the Cookies Policy</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>We reserve the right to modify this Cookies Policy at any time. The modifications will take effect as soon as they are published on the Site.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Modification of the Privacy Policy</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>We reserve the right to modify this Privacy Policy at any time. The modifications will take effect as soon as they are published on the Site.</p>
                    </div>
                </div>
                <div className='Therms_section_text'>
                    <h2>Contact</h2>
                    <div className='Therms_section_text_paragraphe'>
                        <p>If you have any questions or concerns regarding our Privacy Policy, please contact us at info@sustainity.co.</p>
                    </div>
                </div>
            </section>
        </main>
    )
}
export default Therms