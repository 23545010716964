import { useNavigate } from "react-router-dom"


export const FiltreItem = ({ value, nomClass, setMenuIsOpen, setFiltreOpen}) => {
    let navigate = useNavigate()

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }


    const onClick = (e) => {
        scrollToTop()
        navigate('/shop/' + e.target.value)
        setMenuIsOpen(false)
        setFiltreOpen(false)
    }

    return (
        <div className={nomClass}>
            <div className="FiltreItem_text">
                <button className="FiltreItem_button" value={value} onClick={onClick}>{value}</button>
                <span className="FiltreItem_line"></span>
            </div>
            <p className="FiltreItem_number"></p>
        </div>
    )
}

export default FiltreItem