import { useEffect, useRef, useState } from "react"
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export const CarrouselProduct = ({ id, product }) => {
    const [loading, setLoading] = useState(false)

    const storage = getStorage();
    const [imageLinks, setImageLinks] = useState([])

    let index = 0
    const carousel = useRef(null)


    useEffect(() => {
        const getImage = async (refListe) => {
            var images = []
            for (const element of refListe) {
                images.push(await getDownloadURL(element))
            }
            setImageLinks(images)
        }

        const fetchData = async () => {
            let refListe = []
            for (const element of product.imagesLinkTab) {
                let refImage = ref(storage, "products-images/" + id + "/" + element);
                refListe.push(refImage)
            }
            getImage(refListe)
            setLoading(true)
        }
        fetchData()
    }, [loading]
    )

    const generateImage = () => {
        let images = []
        let index = 0
        imageLinks.forEach(img => {
            images.push(<img key={index} src={img} alt={product.nom}></img>)
            index++
        })
        return images
    }

    const onClickNext = () => {
        if (index < imageLinks.length -1) {
            index += 1
            carousel.current.style.transform = `translate(${-100 * index}%, ${0}px)`
        } else {
            index = 0
            carousel.current.style.transform = `translate(${0}%, ${0}px)`
        }
    }

    const onClickMin = () => {
        if (index > 0) {
            index -= 1
            carousel.current.style.transform = `translate(${index-(100 * index)}%, ${0}px)`
        } else {
            index = imageLinks.length -1
            carousel.current.style.transform = `translate(${-index * 100}%, ${0}px)`
        }
    }

    if (loading) {
        return (
            <section className="CarrouselProduct" >
                <button className="CarrouselProduct_button CarrouselProduct_button_prec" onClick={onClickMin}></button>
                <div className="CarrouselProduct_slider" ref={carousel}>
                    {generateImage()}
                </div>
                <button className="CarrouselProduct_button CarrouselProduct_button_next" onClick={onClickNext}></button>
            </section>
        )
    } else {
        return (
            <section className="CarrouselProduct">
                Chargement
            </section>
        )
    }


}
export default CarrouselProduct