export const ChooseColor = ({colors, optionColor, setOptionColor}) => {
    const onClick = (e) => {
        e.preventDefault()
        setOptionColor(e.target.value)
    }
    const generateColorOption = () => {
        let listeOption = []
        colors.forEach(color => {
            if(color.label === optionColor){
                listeOption.push(<div className="ChooseColor_choice_button_decoration" key={color.label} ><button className="ChooseColor_choice_button ChooseColor_choice_button_select" onClick={onClick} value={color.label} style={{backgroundColor: color.value}} ></button></div>)
            } else {
                listeOption.push(<div className="ChooseColor_choice_button_decoration" key={color.label}><button className="ChooseColor_choice_button" onClick={onClick} value={color.label} style={{backgroundColor: color.value}}></button></div>)
            }
        });
        return listeOption
    }
    return (
        <div className="ChooseColor">
            <div className="ChooseColor_text">
                <h3>Choose your color: </h3>
                <p>{optionColor}</p>
            </div>
            <div className="ChooseColor_choice">
                {generateColorOption()}
            </div>
        </div>
    )
}
export default ChooseColor