import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getProduct } from "../../../services/caller.service"
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import CarrouselProduct from "../../../components/public/carrousel/carrouselProduct/CarrouselProduct";
import ProductForm from "../../../components/public/productForm/ProductForm";
import FiltreItems from "../../../components/public/filtres/filtreItems/FiltreItems";
import ProductAriane from "../../../components/public/ariane/productAriane/ProductAriane";
import FiltreItemsMobile from "../../../components/public/filtres/filtreItemsMobile/FiltreItemsMobile";

export const Product = () => {
    const { id } = useParams()
    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(false)
    const [imageDeco, setImageDeco] = useState(null)
    const storage = getStorage();
    const [isClose, setClose] = useState(false)
    const [filtreOpen ,setFiltreOpen] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            setProduct(await getProduct(id))
            setImageDeco(await getDownloadURL(ref(storage, "products-images/" + id + "/secondaryImage1")))
            setLoading(true)
        }
        fetchData()
    }, [loading]
    )

    if (loading) {
        return (
            <main className="Product">
                <section className="Product_hero" >
                    <div className="Shop_left_filtreItems">
                        <FiltreItems setMenuIsOpen={setClose} />
                        <FiltreItemsMobile setMenuIsOpen={setClose} filtreOpen={filtreOpen} setFiltreOpen={setFiltreOpen}/>
                    </div>
                    <div className="Product_hero_container">
                        <ProductAriane categorie={product.categorie} sousCategorie={product.sousCategorie} nomProduit={product.nom} />
                        <div className="Product_car">
                            <div className="Product_carrousel">
                                <CarrouselProduct id={id} product={product} />
                            </div>
                            <ProductForm id={id} product={product} />
                        </div>
                    </div>
                </section>
            </main>
        )
    } else {
        return (
            <main className="Product">
                Chargement
            </main>
        )
    }


}
export default Product