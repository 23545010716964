import { useNavigate } from "react-router"
import PanierAriane from "../../../components/public/ariane/panierAriane/PanierAriane"

export const Commande = ({ socityInformation, setSocityInformation, adresse, setAdresse, setAdresseFacturation, setLogoClient }) => {
    let navigate = useNavigate()

    const onChangeSocityInformation = (e) => {
        setSocityInformation({
            ...socityInformation,
            [e.target.name]: e.target.value //Quand on passe dans onchange on fusionne l'ancien etat avec la nouveauté
        })
    }

    const onChangeAdresse = (e) => {
        setAdresse({
            ...adresse,
            [e.target.name]: e.target.value //Quand on passe dans onchange on fusionne l'ancien etat avec la nouveauté
        })
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
     
    const onSubmit = (e) => {
        e.preventDefault()

        console.log(socityInformation)
        console.log(adresse)
        if(!socityInformation.email == "" || 
        !socityInformation.company == "" ||
        !socityInformation.firstName == "" ||
        !socityInformation.lastName == ""||
        !socityInformation.phoneNumber == ""||
        !socityInformation.vatNumber == ""||
        !adresse.country == ""||
        !adresse.streetAdress == ""||
        !adresse.streetNumber == ""||
        !adresse.zipCode == ""||
        !adresse.city == ""
        ){
            setAdresseFacturation(adresse)
            scrollToTop()
            navigate("/shipping")
        } else{
            console.log("nop")
        }
    }

    return (
        <main className="Commande">

            <section className="Commande_container">
                <PanierAriane />
                <form className="Commande_form">
                    <div className="input-wrapper">
                        <h1>Upload your logo / design</h1>
                        <input
                            type="file"
                            id="logoClient"
                            className="UploadButton"
                            accept=".jpg, .jpeg, .png .ai .svg .eps." 
                            onChange={(event) => setLogoClient(event.target.files[0])}
                        />
                    </div>
                    <h1>Your contact information</h1>
                    <fieldset className="Commande_form_section">
                        <div className="Commande_form_section_sousSection">
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="firstName">First name</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Enter your first name"
                                    value={socityInformation.firstName}
                                    className="input-wrapper_input"
                                    onChange={onChangeSocityInformation}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="lastName">Last name</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Enter your last name"
                                    value={socityInformation.lastName}
                                    className="input-wrapper_input"
                                    onChange={onChangeSocityInformation}
                                />
                            </div>
                        </div>
                        <div className="Commande_form_section_sousSection">
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="phoneNumber">Phone number</label>
                                <input
                                    type="number"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder="Enter your phone number"
                                    value={socityInformation.phoneNumber}
                                    className="input-wrapper_input"
                                    onChange={onChangeSocityInformation}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="email">Email adress</label>
                                <input
                                    type="mail"
                                    id="email"
                                    name="email"
                                    placeholder="Your email adress"
                                    value={socityInformation.email}
                                    className="input-wrapper_input"
                                    onChange={onChangeSocityInformation}
                                />
                            </div>
                        </div>
                        <div className="Commande_form_section_sousSection">
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="company">Company</label>
                                <input
                                    type="text"
                                    id="company"
                                    name="company"
                                    placeholder="Enter your company name"
                                    value={socityInformation.company}
                                    className="input-wrapper_input"
                                    onChange={onChangeSocityInformation}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="vatNumber">VAT Number</label>
                                <input
                                    type="text"
                                    id="vatNumber"
                                    name="vatNumber"
                                    placeholder="Enter your VAT number"
                                    value={socityInformation.vatNumber}
                                    className="input-wrapper_input"
                                    onChange={onChangeSocityInformation}
                                />
                            </div>
                        </div>
                        <div className="input-wrapper">
                            <label className="input-wrapper_label" htmlFor="country">Country</label>
                            <select
                                type="select"
                                id="country"
                                name="country"
                                value={adresse.country}
                                className="input-wrapper_input"
                                onChange={onChangeAdresse}
                                >

                                <option value="">Enter your country</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Germany">Germany</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="France">France</option>
                                <option value="Italy">Italy</option>
                                <option value="Swiss">Swiss</option>
                                <option value="Italy">Italy</option>
                            </select>
                        </div>
                    </fieldset>
                    <fieldset className="Commande_form_section">
                        <div className="Commande_form_section_sousSection">
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="streetAdress">Street adress</label>
                                <input
                                    type="text"
                                    id="streetAdress"
                                    name="streetAdress"
                                    value={adresse.streetAdress}
                                    className="input-wrapper_input"
                                    onChange={onChangeAdresse}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="streetAdress">Street number</label>
                                <input
                                    type="number"
                                    id="streetNumber"
                                    name="streetNumber"
                                    value={adresse.streetNumber}
                                    className="input-wrapper_input"
                                    onChange={onChangeAdresse}
                                />
                            </div>
                        </div>
                        <div className="Commande_form_section_sousSection">
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="zipCode">Zip Code</label>
                                <input
                                    type="number"
                                    id="zipCode"
                                    name="zipCode"
                                    value={adresse.zipCode}
                                    className="input-wrapper_input"
                                    onChange={onChangeAdresse}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="city">City</label>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    value={adresse.city}
                                    className="input-wrapper_input"
                                    onChange={onChangeAdresse}
                                />
                            </div>
                        </div>
                    </fieldset>
                    <div className="Commande_form_buttons">
                        <button className="Panier_container_buttons_button">Previous</button>
                        <button onClick={onSubmit} className="Panier_container_buttons_button" id="Button_Commande">Continue to shipping info</button>
                    </div>
                </form>
            </section>
        </main>
    )
}
export default Commande