import { Link, useLocation, useNavigate } from "react-router-dom";
import Menu from "./Menu/Menu"
import { useState } from "react";
import Panier from "../panier/Panier";


export const Header = () => {
  let navigate = useNavigate()
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const onClickNavigateShop = () => {
    scrollToTop()
    navigate('/shop')
  }

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [panierIsOpen, setPanierIsOpen] = useState(false);

  if (location.pathname === "/") {
    return (
        <header className='Header'>
          <Menu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
          <Link to={"/"} className="Header_logo"><h1>Sustainity</h1></Link>
          <button className="Menu_button Header_menu_desk " id="Menu_button_shop" onClick={onClickNavigateShop}>Shop</button>
        </header>
    )
  } else if (location.pathname === "/order" || location.pathname === "/shipping" || location.pathname === "/summary") {
    return (
      <header className='Header'>
        <Link to={"/"} className="Header_logo"><h1>Sustainity</h1></Link>
      </header>
    )
  }
  return (
    <header className='Header'>
      <Menu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
      <Link to={"/"} className="Header_logo"><h1>Sustainity</h1></Link>
      <Panier panierIsOpen={panierIsOpen} setPanierIsOpen={setPanierIsOpen} />
    </header>
  )
}
export default Header