import { useNavigate } from "react-router-dom"

export const Ariane = ({categorieNom}) => {

    let navigate = useNavigate()
    const onClick = (e) => {
        navigate('/shop/' + e.target.value)
    }

    const generateAriane = () => {
        let arianeListe = []
        let index = 2
        arianeListe.push(<button className="Ariane_button" onClick={onClick} value={"All"} key={0}>/All</button>)

        if (categorieNom === "" || categorieNom === undefined || categorieNom === "All") {
            return arianeListe
        } else {
            index++
            arianeListe.push(<button className="Ariane_button"onClick={onClick} value={categorieNom} key={index}>/{categorieNom}</button>)

        }
        return arianeListe
    }

    return (
        <div className="Ariane">
            {generateAriane()}
        </div>
    )
}
export default Ariane 