import { useNavigate } from "react-router"
import PanierAriane from "../../../components/public/ariane/panierAriane/PanierAriane"
import { useState } from "react"

export const Shipping = ({ adresseFacturation, setAdresseFacturation }) => {
    let navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)
    const onClickPrec = (e) => {
        e.preventDefault()
        navigate("/order")
    }

    const onChangeAdresse = (e) => {
        setAdresseFacturation({
            ...adresseFacturation,
            [e.target.name]: e.target.value //Quand on passe dans onchange on fusionne l'ancien etat avec la nouveauté
        })
    }

    const onClickOpenForm = () => {
        setOpenModal(!openModal)
    }

    const onClickToSummary =(e) =>{
        e.preventDefault()
        navigate("/summary") 
    }

    const isOpen = openModal ? "Shipping_container_modal Shipping_container_modal_open" : "Shipping_container_modal"

    return (
        <main className="Shipping">
            <section className="Shipping_container">
                <PanierAriane />
                <div className="Shipping_container_layout">
                    <div className="Shipping_container_resume">
                        <h1>Invoice and deliver to:</h1>
                        <ul>
                            <li>Street adress: {adresseFacturation.streetAdress}</li>
                            <li>Street number: {adresseFacturation.streetNumber}</li>
                            <li>ZipCode: {adresseFacturation.zipCode}</li>
                            <li>City: {adresseFacturation.city}</li>
                            <li>Country: {adresseFacturation.country}</li>
                        </ul>
                        <button onClick={onClickOpenForm} className="Panier_container_buttons_button">Insert your billing address if different</button>
                    </div>
                    <div className={isOpen}>
                        <fieldset className="Shipping_container_modal_form">

                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="streetAdress">Street adress</label>
                                <input
                                    type="text"
                                    id="streetAdress"
                                    name="streetAdress"
                                    value={adresseFacturation.streetAdress}
                                    className="input-wrapper_input"
                                    onChange={onChangeAdresse}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="streetAdress">Street number</label>
                                <input
                                    type="text"
                                    id="streetNumber"
                                    name="streetNumber"
                                    value={adresseFacturation.streetNumber}
                                    className="input-wrapper_input"
                                    onChange={onChangeAdresse}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="zipCode">Zip Code</label>
                                <input
                                    type="text"
                                    id="zipCode"
                                    name="zipCode"
                                    value={adresseFacturation.zipCode}
                                    className="input-wrapper_input"
                                    onChange={onChangeAdresse}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="city">City</label>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    value={adresseFacturation.city}
                                    className="input-wrapper_input"
                                    onChange={onChangeAdresse}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label className="input-wrapper_label" htmlFor="country">Country</label>
                                <select
                                    type="select"
                                    id="country"
                                    name="country"
                                    value={adresseFacturation.country}
                                    className="input-wrapper_input"
                                    onChange={onChangeAdresse}>

                                    <option value="">Enter your country</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="France">France</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Swiss">Swiss</option>
                                    <option value="Italy">Italy</option>
                                </select>
                            </div>

                        </fieldset>
                    </div>
                </div>
                <div className="Commande_form_buttons">
                    <button onClick={onClickPrec} className="Panier_container_buttons_button">Previous</button>
                    <button onClick={onClickToSummary}className="Panier_container_buttons_button" id="Button_Commande">View summary</button>
                </div>
            </section>
        </main>
    )
}
export default Shipping