import { useState } from "react"
import QuantityButton from "../button/quantityButton/quantityButton";
import OptionDecoration from "../button/optionDecoration/OptionDecoration";
import ChooseColor from "../button/chooseColor/ChooseColor";
import DropDown from "../dropDown/DropDown";
import AddPanier from "../button/addPanier/AddPanier";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../../feature/panier.slice";
import OptionPosition from "../button/optionPosition/OptionPosition";

export const ProductForm = ({ product , id}) => {

    const [optionDecoration, setOptionDecoration] = useState("")
    const [optionPosition, setOptionPosition] = useState("")
    const [optionColor, setOptionColor] = useState("")
    const [addButtonValid, setAddButtonValid] = useState(false)
    const [quantity, setQuantity] = useState(product.quantiteMin * 2)
    const dispatch = useDispatch()
    const panier = useSelector((state) => state.panier.panier)
    let navigate = useNavigate()
 
    const onClickAddPanier = (e) => {
        if (addButtonValid) {
            e.preventDefault()
            console.log(product)
            console.log(quantity)
            console.log(optionDecoration)
            console.log(optionPosition)
            console.log(optionColor)
            let idPanier = panier.length
            dispatch(addProduct({id:idPanier, idProduct:id, product: product, quantity: quantity, optionDecoration: optionDecoration, optionColor: optionColor }))
            
            setQuantity(0)
            setOptionColor("")
            setOptionDecoration("")
            navigate("/shop/product/" + id)
        }
    }
    //<OptionPosition typeDecoration={product.logoPosition} optionPosition={optionPosition} setOptionPosition={setOptionPosition} />

    return (
        <form className="Product_information">
            <div className="Product_information_header"> 
                <h1>{product.nom}</h1>
                <p>{product.description}</p>
            </div>
            <QuantityButton quantity={quantity} setQuantity={setQuantity} quantityMin={product.quantiteMin} />
            <OptionDecoration typeDecoration={product.typeDecoration} optionDecoration={optionDecoration} setOptionDecoration={setOptionDecoration} />
            <ChooseColor colors={product.colors} optionColor={optionColor} setOptionColor={setOptionColor} />
            <div className="Product_information_text">
                <p>Lead time : {product.tempsLivraison} days</p>
                <p>From : {product.prixUniteMin}€ to {product.prixUniteMax}€</p>
            </div>
            <AddPanier
                optionColor={optionColor}
                optionDecoration={optionDecoration}
                quantity={quantity}
                prixUniteMax={product.prixUniteMax}
                prixUniteMin={product.prixUniteMin}
                quantityMin={product.quantiteMin}
                onClickAddPanier={onClickAddPanier}
                setAddButtonValid={setAddButtonValid}
            />
            <DropDown titre={"Specifications"} text={product.specification} />
            <DropDown titre={"Customization"} text={product.customization} />
            <DropDown titre={"Production"} text={product.production} />
        </form>

    )

}
export default ProductForm