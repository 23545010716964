import { useDispatch, useSelector } from "react-redux"
import deleteSVG from "../../../../assets/utils/delete.svg"
import { removeProduct } from "../../../../feature/panier.slice"


export const DeleteButton = (produitPanier) => {
    
    const dispatch = useDispatch()

    const onClickButton = () => {
        dispatch(removeProduct(produitPanier.produitPanier.id))
    }

    return (
        <div onClick={onClickButton} className="DeleteButton">
            <img className="DeleteButton_logo" src={deleteSVG} alt="Logo delete "></img>
        </div>
    )

}
export default DeleteButton