export const filtre = (tableau, filtreSelected) => {
    let tableauFiltre = []
    if (filtreSelected === "All" || filtreSelected === undefined){
        return tableau
    }
    tableau.forEach(produit => {
        if (produit[1].categorie === filtreSelected || produit[1].sousCategorie === filtreSelected){
            tableauFiltre.push(produit)
        }
    });
    return tableauFiltre
}