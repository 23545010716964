export const categories = [
    {
        "nom": "All",
        "sousCategorie": [],
    },
    {
        "nom": "Drinkware",
        "sousCategorie": ["Bottles","Mugs"],
        "nbReference": "03"
    },
    {
        "nom": "Bags",
        "sousCategorie": ["Backpacks", "Totes", "Laptop Bags"],
        "reference": "02"
    },
    {
        "nom": "Textile",
        "sousCategorie": ["Hoodies", "Jackets","T-shirts", "Sweatshirts", "Joggings"],
        "reference": "03"
    },
    {
        "nom": "Office",
        "sousCategorie": ["Notebooks", "Writing"],
        "reference": "04"
    },
    {
        "nom": "Home",
        "sousCategorie": ["Blankets"],
        "reference": "04"
    },
    {
        "nom": "Food",
        "sousCategorie": ["Container"],
        "reference": "05"
    },
    {
        "nom": "Outside",
        "sousCategorie": ["Umbrella"],
        "reference": "06"
    }

]