import { useState } from "react"
import FiltreItems from "../../../components/public/filtres/filtreItems/FiltreItems"
import ListeProduits from "../../../components/public/listeProduits/ListeProduits"
import Ariane from "../../../components/public/ariane/Ariane"
import { useParams } from "react-router-dom"
import { FiltreItemsMobile } from "../../../components/public/filtres/filtreItemsMobile/FiltreItemsMobile"


export const Shop = ({ data }) => {
  const { id } = useParams()
  const [listeProduitsFiltre, setListeProduitsFiltre] = useState(data)
  const [isClose ,setClose] = useState(false)
  const [filtreOpen ,setFiltreOpen] = useState(false)

  return (
    <main className="Shop">
      <div className="Shop_left">
        <div className="Shop_left_filtreItems">
          <FiltreItems setMenuIsOpen={setClose} />
          <FiltreItemsMobile setMenuIsOpen={setClose} filtreOpen={filtreOpen} setFiltreOpen={setFiltreOpen}/>
        </div>
      </div>
      <div className="Shop_right">
        <Ariane categorieNom={id} />
        <ListeProduits categorieNom={id} listeProduits={listeProduitsFiltre} />
      </div>
    </main>
  )
}
export default Shop