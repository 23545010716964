import './App.scss';
import { HashRouter, BrowserRouter, Routes, Route } from "react-router-dom"
import PublicRoute from './pages/public/PublicRoute';


function App() {
  return (
    <HashRouter >
      <Routes>
        <Route path="/*" element={<PublicRoute />} />
      </Routes>
    </HashRouter >
  );
}

export default App;
