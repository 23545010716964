import { Link } from "react-router-dom";
import HubspotForm from 'react-hubspot-form'


export const Footer = () => {
  return (
    <footer className='Footer'>
      <div className="Footer_header">
        <h1>Sustainity</h1>
        <p className="Footer_header_text">We personalize the most beautiful and sustainable products for your brand to shine in real life. </p>
      </div>
      <div className="Footer_header_main">
        <HubspotForm
        className="Footer_header_main_hubspot"
          region="eu1"
          portalId='139700221'
          formId='d71de3b9-2295-4cc0-9a02-87bded15077b'
          onSubmit={() => console.log('Submit!')}
          onReady={(form) => console.log('Form ready!')}
          loading={<div>Loading...</div>}
        />
        <div className="Footer_navigation">
          <nav>
            <ul><Link>About</Link></ul>
            <ul><Link>FAQ</Link></ul>
          </nav>
          <nav>
            <ul><Link>Location</Link></ul>
            <ul><Link to={"https://www.instagram.com/sustainity.co/"}>Instagram</Link></ul>
            <ul><Link to={"https://www.facebook.com/profile.php?id=61550312259853"}>Facebook</Link></ul>
            <ul><Link to={"https://www.linkedin.com/company/sustainity-co/?viewAsMember=true"}>LinkedIn</Link></ul>
            <ul><Link to={"/contact"}>Contact</Link></ul>
          </nav>
          <nav>
            <ul><Link>Plan of site</Link></ul>
            <ul><Link>Privacy</Link></ul>
            <ul><Link to={"/therms"}>Terms</Link></ul>
          </nav>
        </div>
      </div>
      <div className="Footer_copy">
        <p>© 2023 Sustainity. All rights reserved.</p>
      </div>
    </footer>
  )
}
export default Footer