import { useEffect, useRef, useState } from "react"
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import CarteCarrouselHome from "./carteCarrouselHome/CarteCarrouselHome";

export const CarrouselHome = ({ dataBestSeller }) => {
    console.log(dataBestSeller)
    const [loading, setLoading] = useState(false)

    const storage = getStorage();
    const [imageLinks, setImageLinks] = useState([])

    let index = 0
    const carousel = useRef(null)


    useEffect(() => {
        setLoading(true)
    }, [loading]
    )



    if (loading) {
        return (
            <section className="CarrouselHome" >
                <h2>Best sellers</h2>
                <div className="CarrouselHome_container">
                    
                    <div className="CarrouselHome_carrousel">
                        <CarteCarrouselHome produit={dataBestSeller[0]} />
                        <CarteCarrouselHome produit={dataBestSeller[1]} />
                        <CarteCarrouselHome produit={dataBestSeller[2]} />
                        <CarteCarrouselHome produit={dataBestSeller[3]} />
                        <CarteCarrouselHome produit={dataBestSeller[4]} />
                        <CarteCarrouselHome produit={dataBestSeller[5]} />
                        <CarteCarrouselHome produit={dataBestSeller[6]} />
                        <CarteCarrouselHome produit={dataBestSeller[7]} />
                        <CarteCarrouselHome produit={dataBestSeller[8]} />
                        <CarteCarrouselHome produit={dataBestSeller[9]} />
                        <CarteCarrouselHome produit={dataBestSeller[10]} />
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className="CarrouselHome">
                Chargement
            </section>
        )
    }


}
export default CarrouselHome