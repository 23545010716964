import { useNavigate } from "react-router"
import PanierAriane from "../../../components/public/ariane/panierAriane/PanierAriane"
import { useDispatch, useSelector } from "react-redux"
import CartePanierMinimaliste from "../../../components/public/panier/cartePanierMinimaliste/CartePanierMinimaliste"
import { useState } from "react"
import { addCommande, setImageLink } from "../../../services/caller.service"
import { removePanier, removeProduct, setPanier } from "../../../feature/panier.slice"
import { storage } from "../../../config/Config"

export const Summary = ({ socityInformation, adresse, adresseFacturation, setSocityInformation, setAdresse, setAdresseFacturation, logoClient }) => {
    let navigate = useNavigate()
    const dispatch = useDispatch()

    const onClickModifierInfo = () => { 
        navigate("/order")
    }
    const listeItem = useSelector((state) => state.panier.panier)

    const uploadImage = (image, nomImage, docRef) => {
        if (image != null) {
            storage.ref('/commandes/' + docRef + "/" + nomImage).put(image)
            storage.ref('commandes/' + docRef + "/" + nomImage).put(image)
                .on("state_changed", console.log("sucess"), alert)
        }
    }

    const onClickValidate = async (e) => {
        e.preventDefault()

        var random = Math.floor(Math.random() * 1001);;
        let d = new Date();
        let numComande = random + "" + d.getFullYear() + d.getDay() + d.getHours() + d.getMinutes()
        let statut = "A traiter"
        let commande = { socityInformation: socityInformation, adresse: adresse, adresseFacturation: adresseFacturation, panier: listeItem, numComande: numComande, statut: statut }
        console.log(commande)
        const docRef = await addCommande(commande)
        uploadImage(logoClient, "logoClient", docRef)

        setSocityInformation({
            email: "",
            company: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            vatNumber: ""
        })
        setAdresse({
            country: "",
            streetAdress: "",
            streetNumber: "",
            zipCode: "",
            city: ""
        })
        setAdresseFacturation({
            country: "",
            streetAdress: "",
            streetNumber: "",
            zipCode: "",
            city: ""
        })

        uploadImage(logoClient, "logoClient", docRef)
        let panierVide = []

        dispatch(removePanier())
        navigate("/")
    }

    console.log(logoClient)
    return (
        <main className="Summary">
            <section className="Summary_container">
                <PanierAriane /> 
                <div className="Summary_container_article">
                    <article>
                        <h1>Summary</h1>
                        <div className="Summary_panier">
                            <h2>Your quote</h2>
                            {listeItem.map((produitPanier) => (
                                <CartePanierMinimaliste key={produitPanier.id} id={produitPanier.id} produitPanier={produitPanier} />
                            ))}
                        </div>
                        <div className="Summary_container_layout">
                            <h2>Your contact information</h2>
                            <p>Email address: {socityInformation.email}</p>
                            <p>Company: {socityInformation.company}</p>
                            <p>First name: {socityInformation.firstName}</p>
                            <p>Last name: {socityInformation.lastName}</p>
                            <p>Phone number: {socityInformation.phoneNumber}</p>
                            <p>VAT number: {socityInformation.vatNumber}</p>
                        </div>
                        <div className="Summary_container_layout">
                            <h2>Delivery address information</h2>
                            <p>Adresse: {adresse.streetAdress} {adresse.streetNumber}</p>
                            <p>ZipCode: {adresse.zipCode}</p>
                            <p>City: {adresse.city}</p>
                            <p>Country: {adresse.country}</p>
                        </div>
                        <div className="Summary_container_layout">
                            <h2>Billing address information</h2>
                            <p>Company: {socityInformation.company}</p>
                            <p>Adresse: {adresseFacturation.streetAdress} {adresseFacturation.streetNumber} </p>
                            <p>ZipCode: {adresseFacturation.zipCode}</p>
                            <p>City: {adresseFacturation.city}</p>
                            <p>Country: {adresseFacturation.country}</p>
                            <p>VAT number: {socityInformation.vatNumber}</p>
                        </div>
                    </article>

                    <aside className="Summary_aside">
                        <div className="Summary_aside_text">
                            <h2>This is not an order yet!</h2>
                            <p>
                                We will now prepare a perfect offer for you.
                                Once your checkout is complete, we will come back to you within a half day!
                                In the meantime, contact us anytime for questions!</p>
                        </div>
                        <div className="Summary_buttons">
                            <button onClick={onClickModifierInfo} className="Panier_container_buttons_button">Modify informations</button>
                            <button onClick={onClickValidate} className="Panier_container_buttons_button" id="Button_Commande">Validate my request for quotation</button>
                        </div>
                    </aside>
                </div>
            </section>
        </main>
    )
}
export default Summary