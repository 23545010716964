import { createSlice } from "@reduxjs/toolkit";

export const panierSlice = createSlice({
    name: "panier",
    initialState: {
        panier: [],
    },
    reducers: {
        setPanier: (state, action) => {
            state.panier = action.payload
        }, 
        addProduct:(state, action) => {
            state.panier.push(action.payload)
        },
        removeProduct:(state, action) => {
            state.panier = state.panier.filter(item => item.id !== action.payload);
        },
        removePanier:(state, action) => {
            console.log(state.panier)
            console.log(action.payload)
            state.panier = []
            console.log(state.panier)
        }

    }
})

export const { setPanier, addProduct, removeProduct, removePanier } = panierSlice.actions
export default panierSlice.reducer