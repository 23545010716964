import { filtre } from "../../../utils/filtre";
import CarteProduits from "../carteProduits/CarteProduits";

export const ListeProduits = ({ categorieNom, listeProduits }) => {
  const listeDeCartes = []
  const generateCard = () => {
    let listeProduitsFiltre = filtre(listeProduits, categorieNom)
    if (listeProduits.length === 0) {
      listeDeCartes.push(<p>Aucun produit</p>)
      return listeDeCartes
    } else {
      listeProduitsFiltre.forEach(element => {
        listeDeCartes.push(<CarteProduits produit={element} key={element[0]} />)
      });
    }

    return listeDeCartes
  }


  return (
    <section className="ListeProduits">
      {generateCard()}
    </section>
  )
}
export default ListeProduits