import { categories } from "../../../../data/filtre"
import FiltreItem from "../FiltreItem/FiltreItem"
import close from "../../../../assets/utils/close.svg"

export const FiltreItemsMobile = ({setMenuIsOpen, filtreOpen, setFiltreOpen}) => {
    
    const generateFiltre = () => {
        const listeFiltre = []
        categories.forEach(categorie => {
            listeFiltre.push(<FiltreItem setFiltreOpen={setFiltreOpen} setMenuIsOpen={setMenuIsOpen} nomClass={"FiltreItem FiltreItem_principal"} value={categorie.nom} key={categorie.nom}/>)
            categorie.sousCategorie.forEach(element => {
            listeFiltre.push(<FiltreItem setFiltreOpen={setFiltreOpen} setMenuIsOpen={setMenuIsOpen} nomClass={"FiltreItem"} value={element} key={element}/>)
            })
        })
        return listeFiltre
    }

    const OnClickClose = () => {
        setFiltreOpen(!filtreOpen)
    }

    const textButton = filtreOpen ? "Close" : "Select category"

    const isOpen = filtreOpen ? "FiltreItemsMobile FiltreItemsMobile_actif" : "FiltreItemsMobile"
    
    return(
        <>
        <div className={isOpen}>
            <div className="FiltreItemsMobile_liste" onClick={OnClickClose}>
            {generateFiltre()}
            </div>
        </div>
        <button onClick={OnClickClose} className="FiltreItemsMobile_button">{textButton}</button>
        </>
    ) 

        
       
}
export default FiltreItemsMobile