import { Link } from "react-router-dom"

export const HeaderHome = ({bgLink}) => {
    
    return (
      <section className="HeaderHome">
        <img className="HeaderHome_img" src={bgLink} alt="Background home header"></img>
        <Link to={"/shop"} className="HeaderHome_button">Shop</Link>
      </section>
    )
  }
  export default HeaderHome