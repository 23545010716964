import MenuItem from "./MenuItem/MenuItem"
import close from "../../../../assets/utils/close.svg"
import FiltreItems from "../../filtres/filtreItems/FiltreItems"
export const Menu = ({ menuIsOpen, setMenuIsOpen }) => {

  const OnClick = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  const isOpen = menuIsOpen ? "Menu_container Menu_container_open" : "Menu_container"
  return (
    <div className='Menu'>
      <button onClick={OnClick} className="Menu_button_mobil Menu_button">
        <span className="Menu_button_mobil_ligne"></span>
        <span className="Menu_button_mobil_ligne"></span>
        <span className="Menu_button_mobil_ligne"></span>
      </button>
      <button onClick={OnClick} className="Menu_button_desk Menu_button">Menu</button>
      <div className={isOpen}>
        <div className="Menu_container_hero">
          <button onClick={OnClick} className="Menu_container_hero_close"><img src={close} alt="Close menu"></img></button>
          <nav className="Menu_container_hero_nav">
            <ul className="Menu_container_hero_nav_page">
              <MenuItem setMenuIsOpen={setMenuIsOpen} menuIsOpen={menuIsOpen} text={"About"} link={"about"} />
              <MenuItem setMenuIsOpen={setMenuIsOpen} menuIsOpen={menuIsOpen} text={"Shop"} link={"shop"} />
              <MenuItem setMenuIsOpen={setMenuIsOpen} menuIsOpen={menuIsOpen} text={"Contact"} link={"contact"} />
            </ul>
          </nav>
          <nav className="Menu_container_hero_nav">
            <ul className="Menu_container_hero_nav_categorie">
              <FiltreItems setMenuIsOpen={setMenuIsOpen} />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}
export default Menu