import photoSection1 from "../../../assets/images/homeA.png"
import ContainerHome from "../../../components/public/containerHome/ContainerHome"
import Manifeste from "../../../components/public/manifeste/Manifeste"
import imageA from "../../../assets/images/homeA.png"
import imageB from "../../../assets/images/homeB.jpg"
import imageC from "../../../assets/images/homeC.png"

//<Manifeste />
export const About = () => {
    return (
        <main className='About'>
            <ContainerHome
                titre={"Why Sustainity ?"}
                text={"Are you also tired from promotional gifts of questionable quality that you end up throwing in the trash or putting at the bottom of a drawer? "}
                text2={"We said stop and took real action! "}
                text3={"At Sustainity we want to make it right and are fully committed to provide the best and most durable promotional products on the market with high sustainability. " +
                    "We select the best products on a daily basis to make your brand shine, all while being eco-friendly. " +
                    "Whether you're looking to reward your employees or thank your clients, our carefully chosen product ranges let you pick the perfect items to unleash the full potential of your brand. " +
                    "Sustainity is the perfect place for making sustainable textiles and promotional items, in full awareness of the environment. "}
                text4={"We always bring you the most virtuous products and solutions of the moment! "}
                image={imageC}
                imgIsLeft={true}
                buttonLink={"/shop"}
                buttonTitle={"Check it out"}
                buttonIsVisible={true}
            />
            <ContainerHome
                titre={"Let your brand shine with the Perfect Gift, Sustainable and Customizable"}
                text={"Are you looking for the ideal and sustainable corporate gifts, for special events or daily use? Are you an agency working for clients or an event organizer? Are you a freelancer that wants to create or elevate your brand? " +
                    "Let yourself be guided by our cutting-edge selection of the most premium, sustainable and durable promotional products on the market. " +
                    "Let your brand make a strong impact to your clients thanks to our deep knowledge of the market. "}
                text2={"We serve you with the perfect gift for the right target with deep environmental respect. "}
                image={imageB}
                imgIsLeft={false}
                buttonLink={"/shop"}
                buttonTitle={"Let your brand shine"}
                buttonIsVisible={true}
            />
            <ContainerHome
                titre={"Contact us"}
                text={"Any questions regarding our products or customizations? Feel free to contact us by email at info@sustainity.co"}
                image={imageA}
                imgIsLeft={true}
                buttonLink={""}
                buttonTitle={""}
                buttonIsVisible={false}
            />
        </main>
    )
}
export default About