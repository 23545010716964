import { useState, useEffect } from 'react'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Link, NavLink } from "react-router-dom";


export const CarteCarrouselHome = ({ produit }) => {

  const [linkImage, setIinkImage] = useState("")
  const [isHover, setIsHover] = useState(false)
  const [linkImageSecondaire, setIinkImageSecondaire] = useState("")
  const [loading, setLoading] = useState("")
  const [classNameAttribut, setClassNameAttribut] = useState("CarteCarrousel")


  const storage = getStorage();
  const storageRefImageMain = ref(storage, "products-images/" + produit[1].id + "/mainImage");

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }



  useEffect(() => {
    const getImage = async () => {
      setIinkImage(await getDownloadURL(storageRefImageMain))
    }
    getImage()
    setLoading(true)
  }, [loading])


  const handleMouseEnter = () => {
    setClassNameAttribut("CarteCarrousel CarteCarrousel_hover")
    setIsHover(true);

  }

  const handleMouseLeave = () => {
    setClassNameAttribut("CarteCarrousel")
    setIsHover(false);
  }

  if (loading) {
    return (
      <NavLink
        className={classNameAttribut}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={scrollToTop}
        to={"/shop/product/" + produit[1].id}
      >
        <img className="CarteCarrousel_image" src={linkImage}></img>
        <div className="CarteCarrousel_text">
          <p>{produit[1].nom}</p>
          <p>From {produit[1].prix}€</p>
        </div>
      </NavLink >
    )
  } else {
    return (
      <div className="CarteCarrousel">
        Chargement
      </div>
    )
  }
}
export default CarteCarrouselHome